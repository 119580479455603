import { Component, OnInit, OnDestroy } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { SESSION_STORAGE_KEYS } from 'src/app/shared/types/types';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  userData: any = {};
  private subscriptions: Subscription = new Subscription();

  constructor(private sharedService: SharedService, private router: Router,private menu: MenuController) {}

  ngOnInit(): void {
    this.loadUserData();
    const userSessionSubscription = this.sharedService.userSessionCreated.subscribe(() => this.loadUserData());
    this.subscriptions.add(userSessionSubscription);
  }

  private loadUserData(): void {
    this.userData = this.sharedService.getDataFromSessionStorage(SESSION_STORAGE_KEYS.USER_INFO);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  signOut(): void {
    this.sharedService.removeDataToSessionStorage(SESSION_STORAGE_KEYS.SESSION_TOKEN);
    this.sharedService.removeDataToSessionStorage(SESSION_STORAGE_KEYS.USER_INFO);
    this.sharedService.removeDataToSessionStorage(SESSION_STORAGE_KEYS.ACTIVE_CONTEXT);
    this.userData = null;
    this.router.navigate(['login']);
  }

  moveTOBorrowerList(){
    this.menu.toggle();
    this.router.navigate(['borrowers']);
  }
  openMenu() {
    this.menu.toggle();
  }
}
