import { environment } from "src/environments/environment";

const SEEKER_BE_BASE_URL = environment.API.RG_SEEKER_BACKEND_BASE_URL.BASE_URL;

const ENDPOINTS = {
    VERIFY_TOKEN: `${SEEKER_BE_BASE_URL}${environment.API.RG_SEEKER_BACKEND_BASE_URL.END_POINT.AUTH.VERIFY_TOKEN}`,
    BORROWERS: `${SEEKER_BE_BASE_URL}${environment.API.RG_SEEKER_BACKEND_BASE_URL.END_POINT.AUTH.BORROWERS}`,
    SIGN_UP: `${SEEKER_BE_BASE_URL}${environment.API.RG_SEEKER_BACKEND_BASE_URL.END_POINT.AUTH.SIGN_UP}`,
    LOGIN: `${SEEKER_BE_BASE_URL}${environment.API.RG_SEEKER_BACKEND_BASE_URL.END_POINT.AUTH.LOGIN}`,
    PROVIDER_SEARCH: `${SEEKER_BE_BASE_URL}${environment.API.RG_SEEKER_BACKEND_BASE_URL.END_POINT.SEEKER.SEARCH}`,
    PROVIDER_INIT: `${SEEKER_BE_BASE_URL}${environment.API.RG_SEEKER_BACKEND_BASE_URL.END_POINT.SEEKER.INIT}`,
    PROVIDER_CONFIRM: `${SEEKER_BE_BASE_URL}${environment.API.RG_SEEKER_BACKEND_BASE_URL.END_POINT.SEEKER.CONFIRM}`,
    REFRESH_TOKEN: `${SEEKER_BE_BASE_URL}${environment.API.RG_SEEKER_BACKEND_BASE_URL.END_POINT.AUTH.REFRESH_TOKEN}`,
    SEND_VERIFY_OTP: `${SEEKER_BE_BASE_URL}${environment.API.RG_SEEKER_BACKEND_BASE_URL.END_POINT.AUTH.SEND_VERIFY_OTP}`,
};

export { ENDPOINTS}
