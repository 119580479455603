import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, finalize, switchMap, tap } from "rxjs/operators";
import { SharedService } from "src/app/shared/services/shared.service";
import { ToastService } from "src/app/shared/services/toaster.service";
import { LoadingService } from "src/app/shared/services/loading.service";
import { ApiService } from "src/app/shared/services/api.service";
import { ENDPOINTS } from "src/app/shared/endpoints/api-endpoints";
import { LoadingMessage } from "src/app/shared/types/types";
import { SESSION_STORAGE_KEYS } from "src/app/shared/types/types";

@Injectable()
export class InterceptorService implements HttpInterceptor {
  private readonly endpointsToSkipLoading: string[] = [
    '/api/v1/skip-loading-example',
    '/api/v2/no-spinner'
  ];

  private readonly endpointsToSkipToast: string[] = [
    '/api/v1/no-toast-example',
    '/api/v2/no-toast'
  ];

  constructor(
    private sharedService: SharedService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private apiService: ApiService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.sharedService.getDataFromSessionStorage(SESSION_STORAGE_KEYS.SESSION_TOKEN)?.access || null;
    const clonedRequest = this.addHeaders(req, token);

    const skipLoading = this.shouldSkipLoading(req.url);
    const skipToast = this.shouldSkipToast(req.url);

    if (!skipLoading) {
      const loadingMessage = this.getLoadingMessage(req);
      this.loadingService.showOverlayLoading(loadingMessage);
    }
    console.log(req.url)

    return next.handle(clonedRequest).pipe(
      catchError((error: HttpErrorResponse) => {
        this.checkIfAccessTokenExpired(error, req, next, skipToast);
        if (!skipToast) {
          this.handleError(error);
        }
        return throwError(() => error);
      }),
      finalize(() => {
        this.loadingService.hideOverlayLoading();
      })
    );
  }

  private getLoadingMessage(req: HttpRequest<any>): string {
    const loadingMessages: LoadingMessage[] = [
      { endpoint: `${ENDPOINTS.BORROWERS}`, method: 'GET', message: req?.params?.get('id') ? 'Fetching borrower details, please wait...' : 'Fetching borrowers list, please wait...' },
      { endpoint: `${ENDPOINTS.PROVIDER_SEARCH}`, method: 'POST', message: 'We are curating just the right returnable grant offers' },
      { endpoint: `${ENDPOINTS.PROVIDER_INIT}`, method: 'POST', message: req?.body?.borrower_kyc_reference_number ? 'Retrieving your KYC progress, please wait...' : 'Fetching KYC stages, please wait...' },
      { endpoint: `${ENDPOINTS.PROVIDER_CONFIRM}`, method: 'POST', message: 'Verifying the details, please wait...' },
      { endpoint: `${ENDPOINTS.LOGIN}`, method: 'POST', message: 'Logging in, please wait...' },
      { endpoint: `${ENDPOINTS.SIGN_UP}`, method: 'POST', message: 'Signing up, please wait...' },
      { endpoint: `${ENDPOINTS.SEND_VERIFY_OTP}`, method: 'POST', message: 'Sending OTP, please wait...' },
      { endpoint: `${ENDPOINTS.SEND_VERIFY_OTP}`, method: 'PUT', message: 'Verifying OTP, please wait...' },
    ];

    const loadingMessageObj = loadingMessages.find(item => req.url.includes(item.endpoint) && req.method === item.method);
    return loadingMessageObj ? loadingMessageObj.message : 'Loading...';
  }

  private checkIfAccessTokenExpired(error: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler, skipToast: boolean): Observable<HttpEvent<any>> | void {
    const token_expired_message = 'Given token not valid for any token type';
    const message = error?.error?.errors || error?.error?.message;

    if (message.includes(token_expired_message)) {
      const token = this.sharedService.getDataFromSessionStorage(SESSION_STORAGE_KEYS.SESSION_TOKEN)?.refresh;
      if (token) {
        this.apiService.refreshToken({ refresh: token }).pipe(
          tap((res: any) => {
            this.sharedService.setDataToSessionStorage({ data: res, key: SESSION_STORAGE_KEYS.SESSION_TOKEN });
          }),
          switchMap(() => {
            const newToken = this.sharedService.getDataFromSessionStorage(SESSION_STORAGE_KEYS.SESSION_TOKEN)?.access;
            const newClonedRequest = this.addHeaders(req, newToken);
            return next.handle(newClonedRequest);
          }),
          catchError((refreshError) => {
            if (!skipToast) {
              this.handleError(refreshError);
            }
            return throwError(() => refreshError);
          })
        ).subscribe();
      }
    }
    return throwError(() => error);
  }

  private addHeaders(req: HttpRequest<any>, token: string | null): HttpRequest<any> {
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json, text/plain, */*',
      ...(token ? { 'Authorization': `Bearer ${token}` } : {})
    };

    return req.clone({ setHeaders: headers });
  }

  private shouldSkipLoading(url: string): boolean {
    return this.endpointsToSkipLoading.some(endpoint => url.includes(endpoint));
  }

  private shouldSkipToast(url: string): boolean {
    return this.endpointsToSkipToast.some(endpoint => url.includes(endpoint));
  }

  private handleError(error: HttpErrorResponse): void {
    const message = error?.error?.errors || error?.error?.message || 'Something went wrong!';
    this.toastService.presentToast(message, 'danger', 2000, 'bottom', true);
  }

}
