import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SESSION_STORAGE_KEYS } from 'src/app/shared/types/types';
import { ApiService } from 'src/app/shared/services/api.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { ToastService } from 'src/app/shared/services/toaster.service';
import { SignUpPayload } from 'src/app/shared/types/types';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  signupForm = this.fb.group({
    full_name: ['', Validators.required],
    mobile_number: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]]
  });

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private toastService: ToastService,
    private sharedService: SharedService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.clearSession();
  }

  onSubmit() {
    if (this.signupForm.invalid) {
      this.toastService.presentToast('Please fill in all fields correctly.', 'warning');
      return;
    }
    const payload = this.signupForm.value as SignUpPayload
    this.apiService.signUpUser(payload).subscribe(
      (response: any) => {
        this.toastService.presentToast(response.message || 'User created successfully!', 'success');
        this.movetoLogin();
      }
    );
  }

  clearSession() {
    this.signupForm.reset();
    this.sharedService.removeDataToSessionStorage(SESSION_STORAGE_KEYS.SESSION_TOKEN);
    this.sharedService.removeDataToSessionStorage(SESSION_STORAGE_KEYS.USER_INFO);
    this.sharedService.removeDataToSessionStorage(SESSION_STORAGE_KEYS.ACTIVE_CONTEXT);
  }

  movetoLogin() {
    this.router.navigate(['/login']);
  }
}
