import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SESSION_STORAGE_KEYS } from '../types/types';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  userSessionCreated = new Subject();

  constructor() {}

  setDataToSessionStorage({ key, data }: { key: SESSION_STORAGE_KEYS; data: any }) {
    const encryptedData = this.encryptData(data);
    sessionStorage.setItem(key, encryptedData);
  }

  removeDataToSessionStorage(key: SESSION_STORAGE_KEYS) {
    sessionStorage.removeItem(key);
  }

  getDataFromSessionStorage(key: SESSION_STORAGE_KEYS) {
    const encryptedData = sessionStorage.getItem(key);
    if (encryptedData) {
      return this.decryptData(encryptedData);
    }
    return null;
  }

  encryptData(data: any) {
    const stringData = JSON.stringify(data);
    return CryptoJS.AES.encrypt(stringData, environment.SECRET_KEY).toString();
  }

  decryptData(ciphertext: string): any {
    try {
      const bytes = CryptoJS.AES.decrypt(ciphertext, environment.SECRET_KEY);
      const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
      return new Function('return ' + decryptedText)();
    } catch (error) {
      return null;
    }
  }

  getAgeFromDob(date_of_birth: string) {
    if (!date_of_birth) {
      return '';
    }
    const dob = new Date(date_of_birth);
    const today = new Date();
    
    let age = today.getFullYear() - dob.getFullYear();
    const monthDifference = today.getMonth() - dob.getMonth();
  
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < dob.getDate())) {
      age--;
    }
  
    return age;
  }
  
  clearGrantSession(){
    this.removeDataToSessionStorage(SESSION_STORAGE_KEYS.ACTIVE_CONTEXT)
  }
}
